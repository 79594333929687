import {EventEmitter, Injectable} from "@angular/core";
import {DataService} from "../../services/data.service";
import {delay, finalize, take, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {
    AddDeliveryPaymentRequest,
    CartItemSelectorBrief,
    CartItemSelectorFull,
    CartSelector, DeliveryPayment, GiftPriceSelector, GiftSelector, IWebPaySelector
} from "./common";
import {AddressSelector, CompanySelector, UserSelector} from "../address/common";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {CartDialogConfig} from "../cart-modal/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {SettingsService} from "../../services/settings.service";
import {CartChangeModalComponent} from "../cart-modal/cart-change-modal.component";
import {CartTokenService} from "./cart-token.service";
import {DeliveryPaymentService} from "../delivery-payment/delivery-payment.service";
import {loadFromSession, removeFromSession} from "../../helpers/cookie.helper";
import {IReadFileResult} from "../quick-order/quick-order/quick-order.component";
import {
    IAddQuickOrderProductRequest,
    IUploadQuickOrderFileResult
} from "../quick-order/commons/commons";
import {
    IAddOrderProductToCartRequest, IAddOrderProductToCartResult,
    IAddWishListProductToCartRequest,
    IAddWishListProductToCartResult
} from "../../interfaces/general";
import {Router} from "@angular/router";
import {
    ICancelOrderRequest,
    ICancelOrderResult, IGetCartContentForTokenRequest, IMergeCartRequest,
    IMergeCartResult,
    ISyncAbbCartRequest,
    ISyncAbbCartResult
} from "./commons/commons";
import {ToQueryStringParam} from "../../helpers/string.helper";
import {CredentialStorage} from "../../services/credential-storage.service";
import {GtmEventsService, GtmProductItem} from "../../services/gtm-events.service";
import {ProductVariantDetailSelector} from "../product/common";
import {UserService} from "../../services/user.service";
import {CookiesConsentService} from "../cookies-consent/cookies-consent.service";
import {PostRedirectService} from "./post-redirect.service";

@Injectable()
export class CartService {

    getContentDelayMilliseconds: number = 500;

    orderHash: string;

    cartContentChanged: EventEmitter<CartSelector>;
    cartItemCountChanged: EventEmitter<CartItemSelectorBrief>;
    cartInvoiceAddressChanged: EventEmitter<AddressSelector>;
    cartDeliveryAddressChanged: EventEmitter<AddressSelector>;
    cartWithDeliveryAddressChanged: EventEmitter<boolean>;
    cartEmptied: EventEmitter<any>;

    public isNextStep4: boolean = false;

    private _cart: CartSelector;

    set cart(value: CartSelector) {
        this._cart = value;
        if (this.cart && !this._cart?.maxStep) {
            const savedMaxStep = Number(loadFromSession(this.seSvc.cartMaxStepSessionKey));
            if (!Number.isNaN(savedMaxStep)) {
                this.cart.maxStep = savedMaxStep;
            }
        }

        if (this.cart.delivery?.branch) {
            this.cart.withDeliveryAddress = true;
            this.cart.deliveryAddress.street = this.cart.delivery.branch.street;
            this.cart.deliveryAddress.city = this.cart.delivery.branch.city;
            this.cart.deliveryAddress.zipCode = this.cart.delivery.branch.zipcode;
        }

    }

    //region New Implementation
    //cartToken: string;
    //end region

    getDiscount(item: ProductVariantDetailSelector):number{
        let discount = (item.priceWithVAT / item.basePrice - 1) * 100;
        if (Math.abs(discount) < 1) discount = 0;
        //Chteji to zobrazovat kladne
        return (-1) * discount;
    }

    get cart(): CartSelector {
        return this._cart;
    }

    constructor(
        private dataSvc: DataService,
        private seSvc: SettingsService,
        private http: HttpClient,
        private dialogSvc: GeneralDialogService,
        private cartTokenSvc: CartTokenService,
        private delPaySvc: DeliveryPaymentService,
        private router: Router,
        private gtmSvc: GtmEventsService,
        private userSvc: UserService,
        private cookieConsentSvc: CookiesConsentService,
        private prSvc: PostRedirectService
    ) {
        this.cartContentChanged = new EventEmitter();
        this.cartItemCountChanged = new EventEmitter<CartItemSelectorBrief>();
        this.cartInvoiceAddressChanged = new EventEmitter<AddressSelector>();
        this.cartDeliveryAddressChanged = new EventEmitter<AddressSelector>();
        this.cartWithDeliveryAddressChanged = new EventEmitter<boolean>();
        this.cartEmptied = new EventEmitter();

        this.cartContentChanged
            //.pipe(take(1))
            .subscribe(res => {
                if (!this.delPaySvc.destinationCountryId && res.withDeliveryAddress != null) {
                    this.delPaySvc.destinationCountryId = res.withDeliveryAddress ? res.deliveryAddress?.countryId : res.invoiceAddress?.countryId;
                }
            });

        if (location.href.includes('/success/finish')) {
            this.emptyCart();
        } else {
            this.getCartContentForToken();
        }

        this.cartContentChanged.pipe(take(1)).subscribe(()=>{
            this.cart.withDeliveryAddress = this.userSvc.preferredDeliveryAddress != null;
        });
        this.delPaySvc.deliveryPaymentChanged
            .subscribe((res: DeliveryPayment) => {
                setTimeout(() => {
                    this.addDeliveryAndPayment(res);
                }, 0);
            })

        this.initiateModalDialogHook();

    }

    private initiateModalDialogHook() {
        this.cartItemCountChanged
            .subscribe((res: CartItemSelectorBrief) => {

                if (res && !res.fromCart && !res.disableMessage) {

                    let cartItem = this.productList.find(p => p.id == res.id);
                    this.showCartAddDialog(cartItem);
                }
            });
    }

    private showCartAddDialog(cartItem: CartItemSelectorFull): void {
        const data: CartDialogConfig = {cartItem: cartItem};
        const config: GeneralDialogConfig<CartDialogConfig> = {
            data: data,
            cssClassModifier: 'buy',
            isCloseAble: true,
            title: null
        };
        const dialogRef = this.dialogSvc.open(CartChangeModalComponent, config);
        dialogRef.afterClosed
            .subscribe(() => {
            });
    }

    get productList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.productDetail);
        }
        return null;
    }

    get giftList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.giftProductDetail);
        }
        return null;
    }

    get coupons(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.couponDetail);
        }
        return null;
    }

    private _generalPendingOperation: number = 0;
    set generalPendingOperation(value: boolean) {
        if (value) {
            this._generalPendingOperation++;
        } else {
            this._generalPendingOperation--;
        }
    }

    get generalPendingOperation(): boolean {
        return this._generalPendingOperation > 0;
    }

    addToCartMulti(items: CartItemSelectorBrief[]) {
        this.dataSvc.dataLoading = true;
        let url = `api/cart/add-to-cart-multi`;

        items.forEach((i) => {
            i.fromCart = false;
        });

        //ecomail:
        if(this.cookieConsentSvc.isAdsConsent()){
            url+="?isAddsConsent=1"
            const ecmid = loadFromSession("ecmid");
            if (ecmid) {
                url += "&ecmid=" + ecmid;
            }
        }

        this.http.post(url, items)
            .pipe(take(1))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    if(res?.cartActionResult?.success) {
                        //this.token = res.cartToken;
                        this.cart = res;

                        //GTM
                        const gtmItems: Array<GtmProductItem> = [];
                        items.forEach((item) => {
                            const full = this.productList.find(p => p.productId == item.productId);
                            gtmItems.push({
                                item_id: full.productDetail?.code,
                                item_name: full.productDetail?.name,
                                item_brand: full.productDetail?.producerName,
                                item_category: full.productDetail?.nearestCategory?.name,
                                price: full.priceAfterCouponApplied,
                                quantity: items.find(i => i.id == full.productDetail.id)?.count
                            });
                        });
                        this.gtmSvc.addToCart(gtmItems);


                        this.cartContentChanged.emit(res);
                        this.cartItemCountChanged.emit();
                    }
                }, () => {

                });
    }

    addToCart(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;
        let url = `api/cart/addToCart`;

        cart.fromCart = cart.fromCart;

        //ecomail:
        if(this.cookieConsentSvc.isAdsConsent()){
            url+="?isAddsConsent=1"
            const ecmid = loadFromSession("ecmid");
            if (ecmid) {
                url += "&ecmid=" + ecmid;
            }
        }

        this.http.post(url, cart)
            .pipe(take(1))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    if(res?.cartActionResult?.success) {
                        //this.token = res.cartToken;
                        this.cart = res;
                        this.cartContentChanged.emit(res);

                        let full = this.productList.find(p => p.productId == cart.productId);
                        if (full){
                            full.disableMessage = cart.disableMessage;
                            full.fromCart = cart.fromCart;

                            const gtmItem: GtmProductItem = {
                                item_id: full.productDetail?.code,
                                item_name: full.productDetail?.name,
                                item_brand: full.productDetail?.producerName,
                                item_category: full.productDetail?.nearestCategory?.name,
                                price: parseFloat(full.priceAfterCouponApplied.toFixed(2)),
                                quantity: cart.count
                            };
                            this.gtmSvc.addToCart([gtmItem]);
                        }
                        this.cartItemCountChanged.emit(full);
                    }
                }, () => {

                });
    }

    addGiftToCart(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addGiftToCart`;

        cart.fromCart = false;

        this.http.post(url, cart)
            .pipe(take(1))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                }, () => {

                });
    }

    getCartContentForToken(request: IGetCartContentForTokenRequest = null): Promise<void> {
        const p: Promise<void> = new Promise((resolve, reject) => {
            let url = `api/cart/getCartContentForToken?qs=${ToQueryStringParam(request)}`;
            this.http.get<CartSelector>(url)
                .pipe(take(1))
                .pipe(delay(this.getContentDelayMilliseconds))
                .pipe(finalize(() => {
                }))
                .subscribe((res: CartSelector) => {
                    this.cart = res;
                    this.delPaySvc.deliveryPayment = {delivery: this.cart.delivery, payment: CredentialStorage.isUserB2B ?(this.cart.payment ? this.cart.payment :this.delPaySvc.payments.find(x=>x.b2bDefault)) : this.cart.payment};
                    this.cartContentChanged.emit(res);
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }

    broadcastCartEmptied(): void {
        this.cartEmptied.emit()
    }

    removeFromCart(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeFromCart`;

        //ecomail:
        if(this.cookieConsentSvc.isAdsConsent()){
            url+="?isAddsConsent=1"
            const ecmid = loadFromSession("ecmid");
            if (ecmid) {
                url += "&ecmid=" + ecmid;
            }
        }

        this.http.post(url, cart)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.dataSvc.dataLoading = false;
                    this.getCartContentForToken();
                },
                () => {
                    this.dataSvc.dataLoading = false;
                }
            );
    }

    changeCount(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;

        let url = `api/cart/changeCount`;

        this.http.post(url, cart)
            .pipe(take(1))
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res: CartSelector) => {
                this.cart = res;
                let changedItem = this.cart.cartContent.find(item => item.productId == cart.productId);
                if (changedItem) {
                    changedItem.fromCart = cart.fromCart;
                    changedItem.disableMessage = cart.disableMessage;
                    this.cartItemCountChanged.emit(changedItem);
                }
                this.cartContentChanged.emit(res);
            }, () => {

            });

    }

    changeProductCount(productId: number, delta: number): void {
        delta = parseFloat(delta.toString());
        let cartItem = this.cart.cartContent.find(cc => cc.productDetail && cc.productDetail.id == productId);
        if (!cartItem) {
            return;
        }
        let cart: CartItemSelectorBrief = {
            count: cartItem.count + delta,
            id: cartItem.id,
            fromCart: true
        };
        this.changeCount(cart);
    }

    getCouponFromServer(code: string): any {
        let url = `api/coupon/${code}`;
        return this.http.get(url)
    }

    getGifts(priceLevel: number): Observable<GiftSelector[]> {
        let url = `api/gift/getEnabled`;
        let request: GiftPriceSelector = {
            priceLevel: priceLevel
        };
        return this.http.post<GiftSelector[]>(url, request)
    }

    addCouponToCart(couponCode: string): Observable<any> {
        let url = `api/cart/addCouponToCart`;
        const data = {
            couponCode: couponCode,
            Token: this.cartTokenSvc.cartToken,
            FromCart: false
        };

        let sub = new Subject<CartSelector>();

        this.http.post(url, data)
            .pipe(take(1))
            .subscribe((res: CartSelector) => {
                if (res) {

                    /*
                       (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                }
                    */
                    this.cart = res;
                    this.cartContentChanged.emit(res);
                    //this.couponChanged();
                }
                sub.next(res);
            });


        return sub;
    }

    addDeliveryAndPayment(deliveryAndPayment: DeliveryPayment): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            let url = "api/cart/add-delivery-and-payment";

            if (!deliveryAndPayment || !deliveryAndPayment.delivery/* || !deliveryAndPayment.payment*/) {
                resolve();
                return;
            }

            let request: AddDeliveryPaymentRequest = {
                deliveryId: deliveryAndPayment.delivery?.id || null,
                paymentId: deliveryAndPayment.payment?.id || null,
                branch: deliveryAndPayment.delivery?.branch || null
            };

            this.dataSvc.dataLoading = true;
            this.http.post(url, request)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.dataSvc.dataLoading = false;
                    })
                )
                .subscribe((res: CartSelector) => {
                    if (res) {
                        this.cart = res;
                        // todo: toto je hack, musel jsem to umistit tady
                        // protoze navratovy cart selector mi tu nastavenou hodnotu delivery a payment prerazil
                        // cili to je treba tady persistovat a vratit, pokud se vraci cely cart vcetne dopravy a platby
                        // nebo je nevracet na tomto miste
                        this._cart.delivery = deliveryAndPayment.delivery;
                        this._cart.payment = deliveryAndPayment.payment;
                    }
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }

    couponChanged(): void {
        this.cartContentChanged.emit();
    }


    public emptyCart(): void {
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(take(1))
            .subscribe((res) => {
                if (res) {
                    this.resetCart();
                    this.delPaySvc.destinationCountryId = null;
                    this.cartContentChanged.emit(null);
                    this.cartEmptied.emit();
                }
            });
    }

    public emptyCartNextStep(): void {

        this.isNextStep4 = true;
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(take(1))
            .subscribe((res) => {
                if (res) {
                    this.resetCart();
                    this.delPaySvc.destinationCountryId = null;
                    this.cartContentChanged.emit(this.cart);
                    this.cartEmptied.emit();
                }
            });
    }

    public resetCart(): void {
        this.cart = {
            userOrderNumber: null,
            cartActionResult: null,
            cartContent: null,
            delivery: null,
            payment: null,
            priceTotalWithVat: null,
            priceTotalWithOutVat: null,
            priceWithoutPaymentAndDeliveryWithVat: null,
            priceWithoutPaymentAndDeliveryWithOutVat: null,
            itemsCount: null,
            deliveryFreeFrom: null,
            errorMessage_SenKey: null,
            //cartToken: null,
            fromCart: null,
            invoiceAddress: null,
            deliveryAddress: null,
            company: null,
            user: null,

            logToNewsletter: null,
            maxStep: null,
            withCompany: null,
            note: null,
            withDeliveryAddress: null,
            totalVat: null,
            vatPrice: null,
            messages: null,
            deliveryDate: null,
            deliveryOnlyCompleteOrder: false,
            heurekaVerifiedByCustomerDisagreement: this.cart.heurekaVerifiedByCustomerDisagreement //hack: potrebujeme ve step-4 pro metrics api call
        };
        removeFromSession(this.seSvc.cartMaxStepSessionKey);
    }

    public sendOrder(): any {
        let url = 'api/order/save';

        if(this.cookieConsentSvc.isAdsConsent()){
            url+="?isAddsConsent=1"
        }

        return this.http.post(url, this.cart);
    }

    onInvoiceAddressChanged(address: AddressSelector): void {
        this.cart.invoiceAddress = address;
        this.cartInvoiceAddressChanged.emit(this.cart.invoiceAddress);
    }

    onDeliveryAddressChanged(address: AddressSelector): void {
        this.cart.deliveryAddress = address;
        this.cartDeliveryAddressChanged.emit(this.cart.deliveryAddress);
    }

    onCompanyChanged(company: CompanySelector): void {
        this.cart.company = company;
    }

    onWithDeliveryAddressChanged(withDeliveryAddress: boolean): void {
        this.cart.withDeliveryAddress = withDeliveryAddress;
        this.cartWithDeliveryAddressChanged.emit(withDeliveryAddress);
        this.persist();
    }

    onWithCompanyChanged(withCompany: boolean): void {
        this.cart.withCompany = withCompany;
        this.persist();
    }

    onUserChanged(user: UserSelector): void {
        this.cart.user = user;
    }

    cleanCartOnLogout(): void {
        this.cartTokenSvc.clear();
    }

    persist(): Promise<void> {
        const p: Promise<void> = new Promise<void>((resolve, reject) => {
            const url = 'api/cart/persist-cart';
            const data = this.cart;
            this.http.post<boolean>(url, data)
                .pipe(take(1))
                .subscribe(() => {
                    resolve();
                }, () => {
                    reject();
                });
        });

        return p;
    }

    onLogToNewsletterChanged(newsSubsLog: boolean) {
        this.cart.logToNewsletter = newsSubsLog;
        this.persist();
    }

    withCompanyEffectiveValue(): boolean {
        if (this.cart?.withCompany != null) {
            //if withCompany was explicitly set
            return this.cart.withCompany;
        }

        if (this.cart?.company?.ic) {
            return true;
        }

        return false;
    }

    public UploadQuickOrderFile(data: IReadFileResult): Observable<IUploadQuickOrderFileResult> {
        return this.http.post<IUploadQuickOrderFileResult>('api/order/upload-file', data)
            .pipe(tap((res) => {
                this.cart = res.Cart;
                this.cartContentChanged.emit(res.Cart);
            }));
    }

    public AddQuickOrderProducts(request: IAddQuickOrderProductRequest): Observable<IUploadQuickOrderFileResult> {
        return this.http.post<IUploadQuickOrderFileResult>('api/order/add-quick-order-products', request)
            .pipe(tap((res) => {
                this.cart = res.Cart;
                this.cartContentChanged.emit(res.Cart);
            }));
    }

    public AddWishListProductsToCart(request: IAddWishListProductToCartRequest): Observable<IAddWishListProductToCartResult> {
        return this.http.post<IAddWishListProductToCartResult>('api/cart/add-wish-list-products', request)
            .pipe(tap(res => {
                this.cart = res.Cart;
                this.cartContentChanged.emit(res.Cart);
            }));
    }

    public AddOrderProductsToCart(request: IAddOrderProductToCartRequest): Observable<IAddOrderProductToCartResult> {
        return this.http.post<IAddWishListProductToCartResult>('api/cart/add-order-products', request)
            .pipe(tap(res => {
                this.cart = res.Cart;
                this.cartContentChanged.emit(res.Cart);
            }));
    }

    redirectToWebPay(webPayType: string, orderGuid: string): void {
        const webPay = webPayType.toLowerCase();
        this.http.get<IWebPaySelector>(`api/webpay/create/${orderGuid}/${webPay}`)
            .pipe(take(1))
            .subscribe(res => {
                if (res?.gatewayUrl) {
                    window.location.href = res.gatewayUrl;
                } else this.router.navigate([`/kosik/${webPay}/error/finish`]).then(() => {
                });
            });
    }

    redirectToMallPay(object: object): void {

        this.http.post<object>("api/webpay/mallpay/create", object)
            .subscribe(res => {
                if (res) {
                    //console.log(res);
                } else {
                    // console.log('error: ' + object);
                }
            });
    }

    redirectToBarion(orderGuid: string): void {

        this.http.get<IWebPaySelector>(`api/webpay/create/${orderGuid}/barion`)
            .subscribe(res => {
                if (res.gatewayUrl != '') {
                    window.location.href = res.gatewayUrl;
                } else this.router.navigate(['/kosik/barion/error/finish']);
            });
    }

    redirectToGoPay(orderGuid: string): void {
        this.http.get<IWebPaySelector>(`api/webpay/create/${orderGuid}/gopay`)
            .subscribe(res => {
                if (res.gatewayUrl != '') {
                    window.location.href = res.gatewayUrl;
                } else this.router.navigate(['/kosik/gopay/error/finish']).then(() => {
                });
            });
    }

    redirectToComGate(orderGuid: string): void {
        this.http.get<IWebPaySelector>(`api/webpay/create/${orderGuid}/comgate`)
            .subscribe(res => {
                if (res.gatewayUrl) {
                    window.location.href = res.gatewayUrl;
                } else this.router.navigate(['/kosik/comgate/error/finish']).then(() => {
                });
            });
    }

    redirectToGPWebPay(orderHash: string): void {
        /*
        *
        * úspěšnou platbu můžete nasimulovat pomocí testovací karty níže. Dále můžete otestovat, jak se bude brána chovat při špatně zadaném CVC, expiraci, nebo 3D Secure.

                Test Card: 4056070000000008
                Expiry: 12/2020
                CVC2: 200
        *
        * */

        this.http.get<IWebPaySelector>(`api/webpay/create/${orderHash}/gpwebpay`)
            .subscribe(res => {
                debugger;
                if (res.gatewayUrl != '') {
                    // window.location.href = res.gatewayUrl;
                    const url: string = res.gatewayUrl.split('?')[0];
                    const searchString: string = res.gatewayUrl.split('?')[1];
                    const params: URLSearchParams = new URLSearchParams(searchString);
                    this.prSvc.postRedirect(url, params);
                } else this.router.navigate(['/kosik/gpwebpay/error/finish']).then(() => {
                });
            });
    }

    public CancelOrder(request: ICancelOrderRequest): Observable<ICancelOrderResult> {
        return this.http.post('api/order/cancel-order', request);
    }

    public SyncAbbCart(request: ISyncAbbCartRequest): Observable<ISyncAbbCartResult> {
        return this.http.post<ISyncAbbCartResult>('api/cart/sync-abb-cart', request)
            .pipe(tap(r => {
                this.cart = r.Cart;
            }));
    }

    public MergeCart(request: IMergeCartRequest): Observable<IMergeCartResult> {
        const url = `api/cart/merge`;

        //const url = `api/cart/merge/${r.cartTokens[1].cartToken}/${r.cartTokens[0].cartToken}`;
        return this.http.post<IMergeCartResult>(url, request).pipe(tap(mr => {
            this.cartTokenSvc.cartTokens = mr.CartTokens;
        }));
    }

    public RemoveCartItems(): Observable<CartSelector> {
        return this.http.post<CartSelector>(`api/cart/remove-cart-items`, {})
            .pipe(tap(r => {
                this.cart = r;
                this.cartContentChanged.emit(r);
            }));
    }

}
